@media (max-width: 640px) {
    img.header-logo {
        width:130px;
    }

    section.hero {
        background-image: none;
        background-color: white;
    }

    .main-text {
        line-height: 2;
    }

    section#header, section#mission, section#vision, section#principios, section#edificio, section#historia, section#benedicios , section#premios {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 40px;
        padding-bottom: 20px;
    }
    

    .menu-item {
        cursor: pointer;
    }
    
    .menu-item-footer {
        cursor: pointer;
        font-size: 0.7rem;
    }

    .footer-line{
        border: dashed 1px white;
        width: 100%;
    }

    p.p-text {
        font-size: 1rem;
        padding-bottom: 1.5rem;
        text-align: justify;
    }

    .history-item {
        height: auto;
        min-height: 5rem;
    }

    .history-item-text-left {
        height: auto;
        color: #5a5a5a;
        font-size: 0.7rem;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        display: flex;
        padding-left: 10px;
    }
    
    .history-item-text-left strong, .history-item-text-right strong{
        color: #2f2f2f;
    }
    
    .history-item-text-right {
        height: auto;
        color: #5a5a5a;
        font-size: 0.7rem;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        display: flex;
        padding-left: 10px;
    }
    
    .history-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .history-icon.hi-top {
        background-image: none;
        background-color: #003DA5;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .history-icon.hi-green-left {
        background-image: none;
        background-color: #84BD00;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 50px 0px 0px 50px; */
    }
    .history-icon.hi-blue-left {
        background-image: none;
        background-color: #003DA5;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 50px 0px 0px 50px; */
    }
    .history-icon.hi-cyan-left {
        background-image: none;
        background-color: #22C4EB;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 50px 0px 0px 50px; */
    }
    .history-icon.hi-green-right {
        background-image: none;
        background-color: #84BD00;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 0px 50px 50px 0px; */
    }
    .history-icon.hi-blue-right {
        background-image: none;
        background-color: #003DA5;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 0px 50px 50px 0px; */
    }
    .history-icon.hi-cyan-right {
        background-image: none;
        background-color: #22C4EB;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 0px 50px 50px 0px; */
    }
        

    .card {
        position: relative;
        float: left;
        perspective: 500px;
        width: 300px;
        height: 300px;
    }
    
    .card-content {
        width: 100%;
        height: 100%;
        /* box-shadow: 0 0 15px rgba(0,0,0,0.1); */
        
        transition: transform 1s;
        transform-style: preserve-3d;
    }
    
    .card-content.bordered {
        border: solid #003DA5 2px;
        color: #003DA5;
    }
    
    .card:hover .card-content {
        transform: rotateY( 180deg ) ;
        transition: transform 0.5s;
    }
    
    .card-icon {
        width: 120px;
        margin: 20px auto;
    }
    
    .front,
    .back {
        position: absolute;
        height: 100%;
        width: 100%;
        /* background: white;
        line-height: 300px;
        text-align: center;
        border-radius: 5px;*/
        font-size: 0.9rem;
        color: white;
        font-weight: bold;
        backface-visibility: hidden; 
        padding: 20px;
    }
    
    .back {
        /* background: #03446A;
        color: white;  */
        transform: rotateY( 180deg );
    }
    
    
    .card-content.bordered .front,
    .card-content.bordered .back { 
        color: #003DA5;
    }
}

@media (max-width: 1024px) { 
    img.header-logo {
        width:130px;
    }

    section.hero {
        background-image: none;
        background-color: white;
    }

    .main-text {
        line-height: 2;
    }

    p.p-text {
        font-size: 1rem;
        padding-bottom: 1.5rem;
        text-align: justify;
    }

    .history-item {
        height: auto;
        min-height: 5rem;
    }

    .history-item-text-left {
        height: auto;
        color: #5a5a5a;
        font-size: 0.7rem;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        display: flex;
        padding-left: 10px;
    }
    
    .history-item-text-left strong, .history-item-text-right strong{
        color: #2f2f2f;
    }
    
    .history-item-text-right {
        height: auto;
        color: #5a5a5a;
        font-size: 0.7rem;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        display: flex;
        padding-left: 10px;
    }
    
    .history-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .history-icon.hi-top {
        background-image: none;
        background-color: #003DA5;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .history-icon.hi-green-left {
        background-image: none;
        background-color: #84BD00;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 50px 0px 0px 50px; */
    }
    .history-icon.hi-blue-left {
        background-image: none;
        background-color: #003DA5;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 50px 0px 0px 50px; */
    }
    .history-icon.hi-cyan-left {
        background-image: none;
        background-color: #22C4EB;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 50px 0px 0px 50px; */
    }
    .history-icon.hi-green-right {
        background-image: none;
        background-color: #84BD00;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 0px 50px 50px 0px; */
    }
    .history-icon.hi-blue-right {
        background-image: none;
        background-color: #003DA5;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 0px 50px 50px 0px; */
    }
    .history-icon.hi-cyan-right {
        background-image: none;
        background-color: #22C4EB;
        background-size: contain;
        background-repeat: no-repeat;
        /* border-radius: 0px 50px 50px 0px; */
    }
}