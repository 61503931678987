.bg-consorcio-blue {
  background-color: #003DA5;
}

.bg-consorcio-cyan {
  background-color: #22C4EB;
}

.bg-consorcio-green {
  background-color: #84BD00;
}