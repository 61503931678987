img.header-logo {
    width: 200px;
}

section.hero {
    background-image: url('/images/tcn_consorcio_Header.png');
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 50px !important;
    padding-top: 70px !important;
}

section#edificio {
    margin-top: 70 !important;
    margin-bottom: 40px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

section#header, section#mission, section#vision, section#principios, section#edificio, section#historia, section#benedicios, section#premios {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 70px;
    padding-bottom: 40px;
}

.title{
    line-height: 1.2;
    font-size: 3.5rem !important;
}

.main-text {
    line-height: 2.2;
    font-size: 1.1rem;
}

.menu-item-footer {
    cursor: pointer;
    font-size: 0.7rem;
}

.footer-line{
    border: dashed 1px white;
    width: 100%;
}

.menu-item {
    font-size: 0.65rem;
    cursor: pointer;
}

p.p-text {
    font-size: 1rem;
    padding-bottom: 1.5rem;
}

p.text-boxes {
    color: white;
    font-size: 1.3rem;
}

p.text-boxes-o {
    color: #003DA5;
    font-size: 1.3rem;
}

.border-consorcio-blue {
    border: solid 1px #003DA5;
}

.history-item-text-left {
    height: auto;
    color: #5a5a5a;
    font-size: 0.8rem;
    text-align: right;
    flex-direction: column;
    justify-content: center;
    display: flex;
    padding-right: 10px;
}

.history-item-text-left strong, .history-item-text-right strong{
    color: #2f2f2f;
}

.history-item-text-right {
    height: auto;
    color: #5a5a5a;
    font-size: 0.8rem;
    text-align: left;
    flex-direction: column;
    justify-content: center;
    display: flex;
    padding-left: 10px;
}


.history-icon.hi-top {
    background-image: url('/images/tcn_consorcio-point-top.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.history-icon.hi-green-left {
    background-image: url('/images/tcn_consorcio-point-green-left-fixed.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.history-icon.hi-blue-left {
    background-image: url('/images/tcn_consorcio-point-blue-left-fixed.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.history-icon.hi-cyan-left {
    background-image: url('/images/tcn_consorcio-point-cyan-left-fixed.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.history-icon.hi-green-right {
    background-image: url('/images/tcn_consorcio-point-green-right-fixed.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.history-icon.hi-blue-right {
    background-image: url('/images/tcn_consorcio-point-blue-right-fixed.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.history-icon.hi-cyan-right {
    background-image: url('/images/tcn_consorcio-point-cyan-right-fixed.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.award-pic {
    max-width: 100px;
    min-width: 100px;
}


.card {
    position: relative;
    perspective: 500px;
    width: 300px;
    height: 300px;
    background-color: transparent;
}

.card-content {
    width: 100%;
    height: 100%;
    position: relative;    
    transition: transform 1s;
    transform-style: preserve-3d;
}

.card-content.bordered {
    border: solid #003DA5 2px;
    color: #003DA5;
}

.card:hover .card-content {
    transform: rotateY( 180deg ) ;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}

.card-icon {
    width: 120px;
    margin: 20px auto;
}

.front {
    z-index: 2;
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.front,
.back {
    position: absolute;
    height: 100%;
    width: 100%;
    /* background: white;
    line-height: 300px;
    text-align: center;
    border-radius: 5px;*/
    font-size: 0.9rem;
    color: white;
    font-weight: bold;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    padding: 20px;
}

.back {
    /* background: #03446A; */
    /* color: white;  */
    z-index: 3;
    transform: rotateY( 180deg ) ;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
}


.card-content.bordered .front,
.card-content.bordered .back { 
    color: #003DA5;
}

.card-slide-content {
    position: relative;
    width: 300px;
    height: 300px;
}

.card-slide-content.bordered .slide-front,
.card-slide-content.bordered .slide-back-overlay { 
    color: #003DA5;
}

.card-slide-content.bordered {
    border: solid #003DA5 2px;
    color: #003DA5;
}

.slide-back-overlay {
    position: absolute;
    bottom: 300px;
    overflow: hidden;
    width: 300px;
    height: 0;
    transition: .5s ease;
    /* left: -2px; */
}

.card-slide-content.bordered .slide-back-overlay {
    position: absolute;
    bottom: 300px;
    overflow: hidden;
    width: 296px;
    height: 0;
    transition: .5s ease;
    left: 2px;
}

.card-slide-content:hover .slide-back-overlay {
    bottom: 0;
    height: 300px;
}

.card-slide-content.bordered:hover .slide-back-overlay {
    bottom: 0;
    left: 0px;
    height: 296px;
    background-color: white;
}

.card-slide-content:hover .slide-froant {
    display: none;
}

.slide-front p{
    text-align: center;
    padding: 5px 20px;
}

.slide-front,
.slide-back-overlay {
    position: absolute;
    /* height: 100%; */
    /* width: 100%; */
    /* background: white; */
    /* line-height: 300px; */
    text-align: center;
    /* border-radius: 5px; */
    font-size: 0.9rem;
    color: white;
    font-weight: bold;
}

img.img-rrss {
    width: 35px;
    min-width: 35px;
}

.job-item {
    margin-bottom: 1rem;
}

.job-item .job-title {
    padding: 1rem 1.5rem;
    border: none;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    color: #062D5A;
}

.job-item .job-line {
    background-color: #eeeeee;
    color: #383838
}

.job-item .job-button {
    padding: 0.5rem;
    background-color: #062D5A;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    color: white;
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    border-bottom: solid #062D5A 2px;
}

/* .job-item .job-button:hover {
    background-color: #84BD00;
    cursor: pointer;
    border-left: solid #062D5A 2px;
    border-right: solid #062D5A 2px;
    border-bottom: solid #062D5A 2px;
} */

.poferta, .poferta p, .poferta p span, .poferta p span {
    font-family: 'Open Sans' !important;
}