@tailwind base;
@tailwind components;
@tailwind utilities;
 
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('/fonts/OpenSans-Regular.ttf') format('truetype'),
}

html {
    font-family: 'Open Sans';
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

body {
  font-family: 'Open Sans';
  background-color: white;
}

.text-consorcio-blue {
  color: #003DA5;
}

.bg-consorcop-cyan-light {
  background-color: #d7eff8;
}